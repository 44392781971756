@mixin xxx-small{
  @media(max-width: 370px){
    @content;
  }
}
@mixin xx-small{
  @media(max-width: 415px){
    @content;
  }
}
@mixin x-small{
  @media(max-width: 540px){
    @content;
  }
}
@mixin small{
  @media(max-width: 738px){
    @content;
  }
}

@mixin medium{
  @media(max-width: 1024px){
    @content;
  }
}

@mixin medium-2{
  @media(max-width: 1200px){
    @content;
  }
}

@mixin medium-3{
  @media(max-width: 1380px){
    @content;
  }
}

@mixin large{
  @media(max-width: 1520px){
    @content;
  }
}
@mixin x-large{
  @media(max-width: 1920px){
    @content;
  }
}

//COLORS
$orange: #EC682C;
$black: #28221A;
$white: #fff;
$brown: #968C7D;
$gray: #D4D1CA;
$blue: #AAC3D2

