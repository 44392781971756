@import "./variables.scss";
@import "./reset.scss";

.no-scroll {
  overflow: hidden;
}
.disp-mobile {
  display: none;

  @include small {
    display: block;
  }
}
//HOME

.header-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  padding: 40px 20px;
  position: absolute;
  z-index: 4;
  @include medium {
    // margin-top: 100px;
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1180px;
    margin: 0 auto;
    padding: 40px 20px;
    position: absolute;
    z-index: 4;
    top: 0;
    left: 0;
    right: 0;

    .logo img {
      width: 280px;
    }
    .nav-menu-container {
      @include medium {
        display: none;
      }
      .nav-menu {
        display: flex;
        align-items: center;
        gap: 20px;
        li a {
          font-size: 20px;
        }
        .isButton {
          padding: 0.6em 2.7em;
          border-radius: 35px;
          border: 1px solid black;
          font-weight: 700;
          background: rgb(161, 197, 211);
          background: -moz-linear-gradient(
            0deg,
            rgba(161, 197, 211, 1) 52%,
            rgba(204, 224, 231, 1) 89%
          );
          background: -webkit-linear-gradient(
            0deg,
            rgba(161, 197, 211, 1) 52%,
            rgba(204, 224, 231, 1) 89%
          );
          background: linear-gradient(
            0deg,
            rgba(161, 197, 211, 1) 52%,
            rgba(204, 224, 231, 1) 89%
          );
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#a1c5d3",endColorstr="#cce0e7",GradientType=1);
          a {
            font-weight: 700;
          }
        }
      }
    }
    .toggle-menu-mobile {
      display: none;
    }
    .menu-mobile {
      position: absolute;
      width: 100%;
      height: 100%;
      inset: 0;
      background-color: $orange;
      z-index: 4;
      .menu-mobile__top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 30px 20px;
        .logo img {
          width: 190px;
        }

        .toggle-menu-mobile {
          display: block;
          width: 30px;
          cursor: pointer;
        }
      }
      .menu-mobile__middle {
        width: 100%;
        padding: 0 20px;
        background-color: $orange;
        .nav-menu-mobile {
          width: 100%;

          li {
            a {
              padding: 10px 20px;
              display: block;
              width: 100%;
              border: 1px solid white;
              color: $white;
              font-weight: 700;
              font-size: 28px;
              @include x-small {
                font-size: 20px;
              }
            }
            &:nth-child(2) a,
            &:nth-child(3) a {
              border-top: none;
            }
            &:last-child a {
              border: none;
              padding: 0;
            }
            &:last-of-type {
              margin-top: 10px;
              border-radius: 0;
              background-color: $black !important;
              a {
                color: $orange;
              }
              padding: 12px 20px;
            }
          }
        }
      }
      .menu-mobile__bottom {
        padding: 0 20px;
        color: $white;
        background-color: $orange;
        h3 {
          padding-top: 30px;
          font-size: 21px;
          @include x-small {
            font-size: 18px;
          }
        }
        p {
          font-weight: 700;
          font-size: 20px;
          @include x-small {
            font-size: 16px;
          }
          a {
            font-weight: 700;
            font-size: 20px;
            @include x-small {
              font-size: 16px;
            }
          }
        }

        .social-medias {
          display: flex;
          gap: 10px;
          padding: 50px 0;
          div {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40px;
            height: 40px;
            border: 3px solid $black;
            border-radius: 8px;
            img {
              width: 30px;
            }
          }
        }
      }

      .white-space {
        display: none;
        @include medium {
          height: 400px;
          width: 100%;
          background-color: $white;
          display: block;
          z-index: 5;
          position: absolute;
        }
      }
    }
    @include medium {
      .logo img {
        width: 190px;
      }
      .nav-menu-container {
        .nav-menu {
          display: flex;
          align-items: center;
          gap: 20px;
          li a {
            font-size: 20px;
          }
        }
      }
      .toggle-menu-mobile {
        display: block;
        width: 30px;
        cursor: pointer;
      }
    }
  }
  .header-2 {
    border-top: 5px solid $orange;
    padding: 50px 80px 30px;
    max-width: 1380px;
    @include medium-2 {
      padding: 30px 40px;
    }
    @include small {
      padding: 41px 30px;
    }
    .logo img {
      width: 220px;
    }
    @include medium {
      .logo img {
        width: 190px;
      }
    }
    .isButton-2 {
      padding: 0.6em 2.7em;
      border-radius: 35px;
      font-weight: 700;
      background-color: $orange;
      a {
        font-weight: 700;
        color: $white;
      }
    }
  }
}
.active {
  font-weight: bold;
}

.home {
  position: relative;

  .bg-image {
    height: 1080px;
    width: 100%;
    background-repeat: no-repeat;
  }

  .bg-image-mobile,
  .bg-image-medium {
    display: none;
  }

  @include medium {
    .bg-image {
      display: none;
    }
    .bg-image-medium {
      display: block;
      height: 100vh;
      width: 100%;
      background-repeat: no-repeat;
      background-position: top;
      background-size: cover;
    }
  }

  @include small {
    .bg-image-medium {
      display: none;
    }

    .bg-image-mobile {
      display: block;
      height: 100vh;
      width: 100%;
      background-repeat: no-repeat;
      background-position: top;
      background-size: cover;
      position: relative;
      z-index: 1;
    }

    .bg-image-mobile2 {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-repeat: no-repeat;
      background-position: top;
      background-size: cover;
      z-index: 3;
    }
  }

  .slogan-wrapper {
    width: 100%;
    position: absolute; /* Permite sobreposição */
    top: 460px; /* Ajuste a posição vertical conforme necessário */
    left: 50%; /* Centraliza horizontalmente */
    transform: translate(-50%, -50%); /* Centraliza verticalmente */
    z-index: 2; /* Z-index entre as duas imagens */

    @include medium {
      top: 300px; /* Ajuste se necessário */
    }
    @include small {
      top: 340px;
    }
    @include x-small {
      top: 280px;
    }

    .slogan {
      background-color: transparent;
      width: 58.8%;
      margin: 0 auto;
      right: 0;
      z-index: 3;

      @include medium {
        width: calc(100% - 40px);
        left: 20px;
      }

      h2 {
        font-size: 103px;
        font-weight: 700;
        line-height: 0.9;

        @include large {
          font-size: 78px;
        }
        @include medium {
          font-size: 56px;
        }
        @include small {
          font-size: 72px;
        }
        @include x-small {
          font-size: 54px;
        }
        @include xx-small {
          font-size: 48px;
        }
        @include xxx-small {
          font-size: 40px;
        }
      }
      .title-gradient-1 {
        background: rgb(212, 232, 240);
        background: linear-gradient(
          0deg,
          rgba(212, 232, 240, 1) 50%,
          rgba(217, 235, 247, 1) 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        // text-fill-color: transparent;
      }
      .title-gradient-2 {
        background: rgb(220, 235, 241);
        background: linear-gradient(
          0deg,
          rgba(220, 235, 241, 1) 46%,
          rgba(221, 236, 241, 1) 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        // text-fill-color: transparent;
      }
      .title-gradient-3 {
        background: rgb(220, 237, 245);
        background: linear-gradient(
          0deg,
          rgba(220, 237, 245, 1) 46%,
          rgba(227, 239, 243, 1) 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        // text-fill-color: transparent;
      }
      .title-gradient-4 {
        background: rgb(212, 228, 235);
        background: linear-gradient(
          0deg,
          rgba(212, 228, 235, 1) 74%,
          rgba(218, 233, 238, 1) 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        // text-fill-color: transparent;
      }
      .title-gradient-5 {
        background: rgb(222, 234, 239);
        background: linear-gradient(
          0deg,
          rgba(222, 234, 239, 1) 74%,
          rgba(230, 239, 243, 1) 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        // text-fill-color: transparent;
      }
    }
  }
  .hero {
    position: absolute;
    bottom: 0;
    height: 275px;
    width: 100%;
    background-color: rgba(161, 197, 211, 0.8);
    max-width: 1920px;
    margin: auto auto 0;

    @include medium {
      bottom: -200px;
    }
    @include small {
      background-color: inherit;
      bottom: -20px;
      height: auto;
    }

    .hero__top {
      position: relative;
      top: 0;
      height: 220px;
      width: 80%;

      .bg-black {
        background-color: $black;
        height: 100px;
        width: 100%;
        max-width: 88%;
        position: relative;
        left: 50%;
        transform: translateX(-33%);
        top: 0;
        overflow: visible;
        z-index: 1;
      }

      .bg-orange {
        background-color: $orange;
        height: 100px;
        width: 100%;
        max-width: 88%;
        position: absolute;
        left: 50%;
        transform: translateX(-33%);
        top: 100px;
        z-index: 2;
      }

      .content {
        background-color: $white;
        height: 180px;
        width: 94%;
        position: absolute;
        right: 0;
        top: -100px;
        z-index: 3;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 30px 60px 30px 30px;

        &__left {
          li {
            a {
              font-size: 34px;
              font-weight: 700;
            }

            &:first-child a {
              color: $orange;
            }
          }
        }

        &__right .social-medias {
          display: flex;
          gap: 10px;
          div {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40px;
            height: 40px;
            border: 3px solid $black;
            border-radius: 8px;
            img {
              width: 30px;
            }
          }
        }
      }
      @include medium {
        position: relative;
        top: 0;
        height: 220px;
        width: 100%;

        .bg-black {
          height: 100px;
          width: 100%;
          max-width: 100%;
          left: 0;
          transform: translateX(0);
          overflow: visible;
          z-index: 1;
        }

        .bg-orange {
          height: 100px;
          width: 100%;
          max-width: 100%;
          left: 0;
          transform: translateX(0);
          top: 100px;
          z-index: 2;
        }

        .content {
          height: 180px;
          width: 90%;
          position: absolute;
          right: 0;
          top: -100px;
          z-index: 3;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 30px 60px 30px 30px;

          &__left {
            li {
              a {
                font-size: 28px;
              }
            }
          }

          &__right .social-medias {
            display: flex;
            gap: 10px;
            div {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 40px;
              height: 40px;
              border: 3px solid $black;
              border-radius: 8px;
              img {
                width: 30px;
              }
            }
          }
        }
      }
      @include small {
        height: auto;
        width: 100%;
        .bg-black {
          height: 80px;
          width: 100%;
          max-width: 100%;
          left: 0;
          transform: translateX(0);
          overflow: visible;
          z-index: 1;
        }

        .bg-orange {
          height: 80px;
          width: 100%;
          max-width: 100%;
          left: 0;
          transform: translateX(0);
          top: 80px;
          z-index: 2;
        }

        .content {
          height: 140px;
          width: 90%;
          position: absolute;
          right: 0;
          top: -80px;
          z-index: 3;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;
          padding: 10px 20px;
          gap: 10px;

          &__left {
            li {
              a {
                font-size: 20px;
              }
            }
          }

          &__right .social-medias {
            display: flex;
            gap: 10px;
            div {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 34px;
              height: 34px;
              border: 3px solid $black;
              border-radius: 8px;
              img {
                width: 28px;
              }
            }
          }
        }
      }
    }
    .rights {
      width: 100%;
      max-width: 68%;
      margin: 0 auto;
      display: flex;
      justify-content: flex-end;
      @include small {
        margin: 100px 0 20px;
        position: absolute;
        padding-bottom: 20px;
        justify-content: center;
        max-width: 100%;
      }
    }
  }
}
.why-lisbon__wrapper {
  .why-lisbon {
    background-color: #f3f2f0;
    height: 880px;
    width: 100%;
    position: relative;

    @include medium-3 {
      height: 780px;
    }
    @include medium-2 {
      height: 710px;
    }
    @include medium {
      height: 540px;
    }
    @include small {
      height: 370px;
    }
    @include xxx-small {
      height: 330px;
    }

    .why-lisbon__top {
      position: absolute;
      bottom: 0;
      z-index: 1;
      left: 50%;
      transform: translateX(-50%);

      .bg-image {
        height: auto;
        width: 1380px;

        @include medium-3 {
          width: 100vw;
        }

        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }

      .texts {
        position: relative;
        height: 100%;
        width: 1380px;

        @include medium-3 {
          width: 100vw;
        }

        @include medium-2 {
          height: 580px;
        }
        @include medium {
          height: 400px;
        }
        @include small {
          height: 240px;
        }
        @include xxx-small {
          height: 200px;
        }
        .container {
          height: 100%;
          width: 52%;
          padding: 60px 80px;
          margin-left: 0;
          border: 1px solid transparent;
          @include medium-2 {
            display: flex;
            align-items: center;
            padding: 0 38px;
          }
          @include small {
            padding: 0 30px;
          }
          .title {
            z-index: 2;
            font-size: 38px;
            font-weight: 700;
            position: relative;
            font-family: "neue-haas-grotesk-display", sans-serif;
            max-width: 420px;
            @include medium-2 {
              font-size: 54px;
            }
            @include medium {
              font-size: 34px;
              max-width: 250px;
            }
            @include small {
              font-size: 24px;
              max-width: 160px;
            }
            @include xxx-small {
              font-size: 18px;
              max-width: 120px;
            }
            &::after {
              content: "";
              display: block;
              width: 50%;
              height: 2px;
              background-color: black;
              position: absolute;
              bottom: -10px;
              left: 0;
              @include small {
                bottom: -60px;
              }
              @include xxx-small {
                bottom: -54px;
              }
            }
          }
          .content {
            @include medium-2 {
              display: none;
            }
            .text {
              margin: 30px 0;
              font-size: 20px;
              line-height: 1.5;
              @include medium-3 {
                margin: 20px 0;
                font-size: 16px;
                line-height: 1.5;
              }
            }
            .legend {
              display: flex;
              justify-content: space-between;
              p {
                font-size: 18px;

                @include medium-3 {
                  font-size: 16px;
                }
              }
            }
            .datas {
              margin: 10px 0;
              .data {
                display: flex;
                justify-content: space-between;
                padding: 10px;
                border-left: 6px solid #41819c;
                margin-bottom: 2px;
                background-color: rgba(0, 0, 0, 0.1);
                p {
                  font-size: 18px;
                  @include medium-3 {
                    font-size: 16px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .why-lisbon__bottom {
    display: none;
    @include medium-2 {
      display: block;
      .container {
        background-color: $blue;
        padding: 40px;
        @include small {
          padding: 30px;
        }
        .title {
          display: none;
        }
        .text {
          margin-bottom: 30px;
        }
        .legend {
          display: flex;
          justify-content: space-between;
          p {
            font-size: 16px;
            color: $white;

            @include medium-3 {
              font-size: 16px;
            }
          }
        }
        .datas {
          margin: 10px 0;
          .data {
            display: flex;
            justify-content: space-between;
            padding: 10px;
            border-left: 6px solid #41819c;
            margin-bottom: 2px;
            background-color: #cfe2e9;
            p {
              font-size: 14px;
            }
          }
        }
        .source {
          color: $white;
        }
      }
    }
  }
}

.why-lisbon-2__wrapper {
  width: 100%;
  max-width: 1264px;
  padding: 40px 20px;
  margin: 0 auto;
  @include medium-2 {
    padding: 40px 40px;
  }
  @include small {
    padding: 30px;
  }
  h2 {
    font-size: 36px;
    font-weight: 700;
    max-width: 450px;
    margin-bottom: 40px;
    line-height: 1.1;

    @include medium {
      font-size: 30px;
      max-width: 380px;
    }
    @include small {
      margin-bottom: 20px;
      font-size: 24px;
      max-width: 300px;
    }
    @include xxx-small {
      font-size: 21px;
      max-width: 260px;
    }
  }
  .why-lisbon-2 {
    display: flex;
    gap: 80px;
    @include medium {
      flex-direction: column;
      gap: 40px;
    }
    @include small {
      flex-direction: column;
      gap: 20px;
    }
    .charts {
      width: calc(50% - 40px);
      border-left: 1px solid $black;
      display: flex;
      flex-direction: column;
      gap: 10px;
      height: fit-content;
      padding-bottom: 20px;
      @include medium {
        width: 100%;
      }
      h3 {
        font-size: 24px;
        padding-left: 20px;
        font-weight: 700;

        @include medium {
          font-size: 20px;
        }
        @include xxx-small {
          font-size: 18px;
        }
      }
      .data {
        background-color: #f3f2f0;
        display: flex;
        justify-content: space-between;
        padding: 10px;
      }
    }
    .texts {
      width: calc(50% - 40px);
      display: flex;
      flex-direction: column;
      gap: 20px;
      @include medium {
        width: 100%;
      }
      p {
        font-size: 20px;

        @include medium {
          font-size: 16px;
        }
      }
    }
  }
}

.your-investment-project__wrapper {
  .your-investment-project {
    background-color: #f3f2f0;
    width: 100%;
    position: relative;
    color: white;
    height: 726px;

    // @include medium-3 {
    //   height: 780px;
    // }
    @include medium-2 {
      height: 590px;
    }
    @include medium {
      height: 530px;
    }
    @include small {
      height: 410px;
    }
    @include x-small {
      height: 370px;
    }
    @include xx-small {
      height: 320px;
    }
    @include xxx-small {
      height: 300px;
    }

    .your-investment-project__top {
      position: absolute;
      bottom: 0;
      z-index: 1;
      left: 50%;
      transform: translateX(-50%);

      .bg-image {
        height: 560px;
        width: 1380px;

        @include medium-3 {
          width: 100vw;
        }
        @include medium-2 {
          height: 460px;
        }
        @include medium {
          height: 400px;
        }
        @include small {
          height: 280px;
          display: none;
        }

        background-size: cover;
        background-position: 80% center;
        background-repeat: no-repeat;
      }
      .bg-image-mobile {
        display: none;
        @include small {
          display: block;
          height: 560px;
          width: 100vw;
          max-width: 100%;
          height: 280px;
          background-size: cover;
          background-position: 80% center;
          background-repeat: no-repeat;
        }
        @include x-small {
          height: 240px;
        }
        @include xx-small {
          height: 190px;
        }
        @include xxx-small {
          height: 170px;
        }
      }
      .texts {
        position: relative;
        height: 100%;
        width: 1380px;

        @include medium-3 {
          width: 100vw;
        }

        @include medium-2 {
          height: 580px;
        }
        @include medium {
          height: 400px;
        }
        @include small {
          height: 300px;
        }
        @include x-small {
          height: 240px;
        }
        @include xx-small {
          height: 190px;
        }
        @include xxx-small {
          height: 190px;
        }

        .container {
          height: 100%;
          width: 52%;
          padding: 60px 80px;
          margin-left: 0;
          border: 1px solid transparent;
          @include medium-2 {
            display: flex;
            align-items: center;
            padding: 0 20px;
          }
          .title {
            z-index: 2;
            font-size: 38px;
            font-weight: 700;
            position: relative;
            font-family: "neue-haas-grotesk-display", sans-serif;
            max-width: 450px;

            @include medium-2 {
              font-size: 54px;
              padding-left: 20px;
            }
            @include medium {
              font-size: 34px;
              max-width: 250px;
            }
            // @include small {
            //   font-size: 24px;
            //   max-width: 160px;
            // }
            @include x-small {
              padding-left: 10px;
              font-size: 24px;
              max-width: 144px;
            }
            @include xxx-small {
              font-size: 18px;
              max-width: 140px;
              padding-left: 10px;
            }
            &::after {
              content: "";
              display: block;
              width: 50%;
              height: 2px;
              background-color: white;
              position: absolute;
              bottom: -10px;
              left: 0;
              @include medium-2 {
                margin-left: 20px;
              }
              @include small {
                bottom: -74px;
                display: none;
              }
              @include x-small {
                display: none;
              }
            }
          }
          .content {
            @include medium-2 {
              display: none;
            }
            .text {
              margin: 30px 0;
              font-size: 20px;
              line-height: 1.5;
              @include medium-3 {
                margin: 20px 0;
                font-size: 16px;
                line-height: 1.5;
              }
            }
            .legend {
              display: flex;
              justify-content: space-between;
              p {
                font-size: 18px;

                @include medium-3 {
                  font-size: 16px;
                }
              }
            }

            .datas {
              margin: 10px 0;

              .data {
                display: flex;
                justify-content: space-between;
                padding: 10px;
                margin-bottom: 2px;
                font-size: 14px;
                background-color: #f1d9ab;
                color: black;
              }
            }
          }
        }
      }
    }
  }

  .your-investment-project__bottom {
    display: none;
    @include medium-2 {
      display: block;
      .container {
        background-color: #968d7c;
        padding: 40px;
        color: $white;
        @include x-small {
          padding: 30px;
        }
        .title {
          display: none;
        }
        .text {
          margin-bottom: 30px;
        }
        .legend {
          display: flex;
          justify-content: space-between;
          p {
            font-size: 16px;
            color: $white;

            @include medium-3 {
              font-size: 16px;
            }
          }
        }
        .datas {
          margin: 10px 0;
          .data {
            display: flex;
            justify-content: space-between;
            padding: 10px;
            margin-bottom: 2px;
            background-color: #cfe2e9;
            p {
              font-size: 14px;
            }
            .months-left {
              color: black;
            }
          }
        }
      }
    }
  }
  .project-details {
    width: 100%;
    max-width: 1420px;
    padding: 0 20px;
    margin: 0 auto 40px;
    p,
    p span {
      font-size: 18px;
    }
    .real-estate-deal {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #968d7c;
      color: $white;
      padding: 15px;

      @include small {
        width: 100vw;
        margin-left: -20px;
        padding: 15px 20px;
      }
      @include x-small {
        p{
          font-size: 16px;
        }
      }
    }
    .delivery-time {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 15px;
      @include small {
        width: 100vw;
        margin-left: -20px;
        padding: 15px 20px;
      }
      @include x-small {
        p{
          font-size: 16px;
        }
      }
    }
    .label {
      display: flex;
      gap: 20px;
      margin: 20px 0;

      span {
        font-weight: 700;
      }
      @include x-small {
        p, span{
          font-size: 16px;
          margin: -10px 0;
        }
      }
    }
    .title {
      font-weight: 700;
      font-size: 38px;
      border-bottom: 1px solid $black;
      padding-bottom: 20px;
      margin: 60px 0 20px;

      @include small {
        font-size: 30px;
        max-width: 360px;
        border: none;
        margin: 40px 0 10px;
      }
    }
    .general-information {
      display: flex;
      gap: 80px;

      @include medium {
        flex-direction: column;
        gap: 40px;
      }
      .general-information__left {
        width: calc(50% - 40px);
        font-size: 20px;
        line-height: 1.5;
        @include medium {
          width: 100%;
        }
        @include small {
          
          font-size: 18px;
        }
      }
      .general-information__right {
        width: calc(50% - 40px);
        font-family: "neue-haas-grotesk-display", sans-serif;

        @include medium {
          width: 100%;
        }

        .general-information__right--top {
          display: flex;
          flex-wrap: wrap;
          gap: 20px 60px;

          @include small {
            flex-direction: column;
          }
          p,
          p span {
            font-size: 20px;
            @include x-small {
              font-size: 18px;
            }
          }
          p span {
            font-weight: 700;
          }
        }
        .general-information__right--bottom {
          margin-top: 20px;
          @include small {        
            border-bottom: 1px solid $black;
            width: 100vw;
            margin-left: -20px;
            padding: 0 20px 20px;
          }
          p {
            margin-bottom: 10px;
            font-size: 20px;
            @include small {
              font-size: 18px;
            }
          }
          ul {
            padding-left: 20px;
            margin: 0;

            li {
              margin-bottom: 8px;
              list-style-type: disc;
              font-weight: 700;
              font-size: 20px;

              @include small {
                font-weight: 400;
                font-size: 18px;
              }
            }
          }
        }
      }
    }
  }

  .slider__wrapper {
    .slider-mobile {
      display: none;
    }
    .title__wrapper {
      width: 100%;
      max-width: 1420px;
      margin: 0 auto 50px;
      padding: 0 20px;
      .title {
        font-weight: 700;
        font-size: 38px;
        border-bottom: 1px solid $black;
        padding-bottom: 30px;
        margin: 0 auto;
        @include small {
          font-size: 30px;
          max-width: 360px;
          border: none;
          margin: 40px 0 -80px;
        }
      }
    }
    .slider {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin: 0 auto;

      .side-img-slider {
        width: 250px;
        height: 580px;
        object-fit: cover;
        overflow: hidden;
        opacity: 0.3;
      }

      button {
        background-color: #968d7c;
        border: none;
        font-size: 40px;
        cursor: pointer;
        color: #fff;
        transition: color 0.3s;
        margin: 20px;
        padding: 11px 21px;
      }
      .button-1 {
        margin-right: 22px;
      }

      .button-2 {
        margin-left: 22px;
      }

      .img-slider {
        height: 580px;
        width: 100%;
        max-width: 1200px;
        cursor: pointer;
      }
    }
    .slider__bottom--wrapper {
      width: 100%;
      @include small {
        height: 100px;
        border-bottom: 1px solid $black;
      }
      .slider__bottom {
        background-color: #968d7c;
        color: $white;
        padding: 15px;
        width: 100%;
        max-width: 1200px;
        margin: 15px auto 80px;
        
      @include xx-small {
       p {
        font-size: 12px;
       }
      }
      }
    }

    @include x-large {
      .slider {
        .side-img-slider {
          width: 250px;
          height: 580px;
        }

        .img-slider {
          width: 60% !important;
        }
      }
    }
    @include large {
      .slider {
        .side-img-slider {
          width: 250px;
          height: 460px !important;
        }
        .img-slider {
          height: 460px !important;
          width: 60% !important;
        }
      }
    }

    @include medium {
      .slider {
        display: none;
      }
      .slider__bottom--wrapper {
        width: 100%;
        padding: 0 20px;
        .slider__bottom {
          width: 70%;
          margin: -10px 0 40px;
        }
      }

      .slider-mobile {
        overflow-x: auto;
        white-space: nowrap;
        display: flex;
        padding: 20px 0;
        -webkit-overflow-scrolling: touch;
        scrollbar-width: none;
      }

      .slider-mobile__wrapper {
        display: flex;
        gap: 20px;
        margin: 0 20px;
      }

      .slider-mobile__image {
        width: 70% !important;
        height: auto;
        display: inline-block;
        margin: 0 0px;
        transition: opacity 0.5s ease;
      }
    }
    .modal-overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      background-color: rgba(255, 255, 255, 0.8);
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1000;
    }
    
    .modal-content {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      max-width: 1920px;
      max-height: 90vh;

      img{
        
      max-width: calc(100% - 160px);
      max-height: 90vh;

      @include small {
        max-width: calc(100% - 130px);
      }
      }
    }
    
    .modal-image {
      width: 100%;
      max-width: 80%;
      max-height: 90vh;
      img{
        
        max-width: 90vw;
        max-height: 90vh;
        }
    }
    
    .modal-button {
      background-color: #968d7c;
      font-size: 40px;
      color: #fff;
      cursor: pointer;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 50px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 5;
      right: 10px;
      @include small {
        width: 40px;
        height: 40px;
        font-size: 30px;
      }
    }
    
    .modal-button:first-of-type {
      left: 10px;
    }
    
    .modal-close {
      position: absolute;
      top: 10px;
      right: 18px;
      background: $white;
      padding: 5px;
      border-radius: 100%;
      font-size: 1.5rem;
      cursor: pointer;
      color: $black;
      width: 40px;
      @include small {
        width: 35px;
        right: 12px;
      }
      @include x-small {
       top: -5px;
      }
    }
  }

  .column-content {
    width: 100%;
    max-width: 1420px;
    padding: 0 20px;
    margin: 0 auto 40px;
    .title {
      font-weight: 700;
      font-size: 38px;
      border-bottom: 1px solid $black;
      padding-bottom: 20px;
      margin: 60px 0 20px;
      @include small {
        font-size: 30px;
        border: none;
        margin: 40px 0 0px;
      }
    }
    .column-text {
      column-count: 2;
      column-gap: 40px;
      font-size: 20px;

      @include small {
        column-count: 1;
        font-size: 18px;
      }
    }
  }

  .map {
    width: 100%;
    max-width: 1920px;
    height: 400px;
    margin-bottom: 50px;
    @include small {
      margin-bottom: 0;
    }
    iframe {
      width: 100%;
      height: 100%;
    }
  }
}

.your-investment-project-2__wrapper {
  width: 100%;
  max-width: 1420px;
  padding: 0 20px;
  margin: 0 auto;
  @include small {
    padding: 0;
  }
  h2 {
    font-size: 40px;
    font-weight: 700;
    max-width: 720px;
    padding: 40px 60px;
    @include medium-2 {
      padding: 40px 20px;
      max-width: 640px;
    }
    @include small {
      font-size: 28px;
      max-width: 460px;
    }
    @include xx-small {
      font-size: 22px;
      max-width: 360px;
    }
  }

  .projects {
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    margin-bottom: 40px;
    @include medium-2 {
      gap: 20px;
    }

    .project {
      position: relative;
      width: calc(50% - 20px);
      max-width: 675px;
      height: auto;
      margin-bottom: 40px;
      cursor: pointer;
      @include medium-2 {
        width: calc(50% - 10px);
      }
      @include small {
        width: 100%;
        max-width: 100%;
      }
      @include x-small {
        margin-bottom: 32px;
      }

      .project-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .project--description,
      .price {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 10px;
        color: white;
        background: rgba(0, 0, 0, 0.5);
        width: 83%;
        margin: 0 auto;

        .area {
          display: flex;
          gap: 5px;
          p {
            font-size: 18px;
            font-weight: normal;
            @include medium {
              font-size: 16px;
            }
            @include x-small {
              font-size: 14px;
            }
          }
        }
      }
      .project--description {
        h3,
        p {
          font-size: 30px;
          font-weight: 700;

          @include medium-2 {
            font-size: 24px;
          }
          @include medium {
            font-size: 20px;
          }
          @include x-small {
            font-size: 18px;
          }
        }
      }

      .price {
        bottom: -40px;
        height: 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
        border-left: 6px solid #41819c;
        background-color: #d5d1c8;
        @include x-small {
          height: 32px;
          bottom: -32px;
        }
        p {
          color: $black;
          margin: 0;
          font-size: 18px;
          @include medium {
            font-size: 16px;
          }
          @include x-small {
            font-size: 14px;
          }
        }
      }
    }
  }
}

.about-2 {
  width: 100%;
  max-width: 1420px;
  padding: 0 20px;
  margin: 0 auto 125px;
  @include medium {
    margin-bottom: 80px;
  }
  @include medium {
    margin-bottom: 40px;
  }
  h2 {
    font-size: 40px;
    font-weight: 700;
    max-width: 720px;
    padding: 40px 60px;
    @include medium-2 {
      padding: 40px 20px;
      max-width: 640px;
    }
    @include small {
      font-size: 28px;
      max-width: 460px;
    }
    @include x-small {
      max-width: 320px;
      padding: 40px 0px;
    }
    @include xx-small {
      font-size: 22px;
      max-width: 360px;
    }
  }

  .services {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 40px;
    @include medium-2 {
      gap: 20px;
    }

    .service {
      position: relative;
      width: calc((100% / 3) - (20px * 2 / 3));
      height: auto;
      margin-bottom: 40px;
      background-color: #e5e2dd;
      padding: 0 20px 20px 20px;
      @include medium {
        width: 100%;
        margin-bottom: 0px;
      }
      .service-border {
        width: 30%;
        height: 10px;
        background-color: $orange;
        margin-bottom: 20px;
        @include medium {
          width: 22%;
          height: 8px;
        }
      }
      h3 {
        font-family: "neue-haas-grotesk-display", sans-serif;
        font-weight: 700;
        font-size: 24px;
        max-width: 200px;
        margin-bottom: 10px;
        @include x-small {
          font-size: 20px;
        }
      }
      p {
        font-size: 18px;
        @include x-small {
          font-size: 16px;
        }
      }
    }
  }
}

footer {
  width: 100%;
  max-width: 1420px;
  padding: 0 20px;
  margin: 0 auto;
  @include medium {
    padding: 0;
  }
  .footer-desktop {
    .footer-desktop__social-medias {
      display: flex;
      gap: 10px;
      width: 100%;
      padding-bottom: 20px;
      margin-bottom: 20px;
      border-bottom: 2px solid $black;
      a img {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        border: 3px solid $black;
        border-radius: 8px;
      }
    }
    &__content {
      display: flex;
      justify-content: space-between;
      margin-bottom: 80px;
      &--left {
        display: flex;
        gap: 40px;
        h3 {
          font-weight: 700;
        }
        p,
        h3,
        a {
          font-size: 20px;
          line-height: 1.5;
        }
      }
    }
  }
  .footer-mobile {
    display: none;
  }
  @include medium {
    .footer-desktop {
      display: none;
    }
    .footer-mobile {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: $orange;
      padding: 10px 20px;

      a {
        color: $white;
      }
      &__social-medias {
        display: flex;
        gap: 5px;
        a {
          border: 1px solid $white;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      @include x-small {
        gap: 10px;
        a {
          font-size: 14px;
        }
        &__social-medias {
          display: flex;
          gap: 5px;
          a {
            border: 1px solid $white;
            border-radius: 50%;
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              transform: scale(0.8);
            }
          }
        }
      }
    }
  }
}
