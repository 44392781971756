*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
  font-size:16px;
  font-weight: normal;
  background-color: transparent;
  border: none;
  }

html,
body
{
    width: 100%;
    max-width: 100%;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: "neue-haas-grotesk-text", sans-serif; //neue-haas-grotesk-text e neue-haas-grotesk-display
}

img{
  max-width: 100%;
  height: auto;
  vertical-align: middle;
  font-style: italic;
  background-repeat: no-repeat;
  background-size: cover;
  shape-margin: 0.75rem;
 }

 .container
{
  width: 100%;
  max-width: 1920px;
  height: 100%;
  margin: 0 auto;
}

a,
a:hover,
a:focus,
a:active,
button,
button:hover {
  text-decoration: none;
  cursor: pointer;
  color: inherit;
} 